import React from 'react';

import SocialIcons from '@elements/SocialIcons';
import { getSocialList } from '@utils';
import { Link } from '@elements/Link';
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";

import './style.css';

type SocialMediaProps = {
  contentful_id: string,
  facebookLink: string,
  instagramLink: string,
  linkedInLink: string,
  twitterLink: string,
};

type CardPersonType = {
  person: {
    contentful_id: string,
    title: string,
    name: string,
    slug: string,
    image: {
      title: string,
      gatsbyImageData: IGatsbyImageData,
    }
    socialMedia: SocialMediaProps,
  },
};

const CardPerson = ({ 
  person:{
    title,
    name,
    slug,
    image: preImage,
    socialMedia,
  },
}: CardPersonType): JSX.Element => {
  const image = getImage(preImage?.gatsbyImageData);
  const socialList = getSocialList(socialMedia);

  return (
    <div className="card-person--main">
      <div>
        <p>
          { title }
        </p>
        <Link to={`/${slug}`}>
          <figure>
            <GatsbyImage
              alt={preImage.title}
              image={image}
            />
          </figure>
          <h2>
            { name }
          </h2>
        </Link>
        <SocialIcons 
          childClassName="card-person__social-icons" 
          socialList={socialList} 
        />
      </div>
    </div>
  );
};

export default CardPerson;
