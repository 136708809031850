/* eslint-disable import-helpers/order-imports */
import React from 'react';

import Carousel from '@elements/Carousel';
import CardPerson from '@elements/CardPerson';
import RichText from '@elements/RichText';
import SocialIcons from '@elements/SocialIcons';
import { getSocialList } from '@utils';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import '../styles/templates/person.css';

type PersonProps = {
  data,
  pageContext
}

const Person: React.FC<PersonProps> = ({
  data,
  pageContext,
}) => {
  const { person, peopleList } = data;

  const newPeopleList = peopleList?.people.filter(
    ({ contentful_id }) => contentful_id !== pageContext?.contentful_id
  );

  const image = getImage(person.image?.gatsbyImageData);
  const socialList = getSocialList(person.socialMedia);
  const breakpoints = {
    sm: 2,
    lg: 3,
  };

  return (
    <div className="person--main">
      <div className="person__inner">
        <section className="person__right-side">
          <figure>
            <GatsbyImage alt={person.image?.title} image={image} />
          </figure>
          <SocialIcons 
            childClassName="card-person__social-icons" 
            socialList={socialList} 
          />
        </section>
        <section className="person__left-side">
          <hr className="person__line" />
          <h1 className="person__name">
            {person.name}
          </h1>
          <p className="person__title">
            {person.title}
          </p>
          <div className="person__description">
            {
              person?.description && (
                <RichText bodyRichText={person.description} />
              )
            }
          </div>
        </section>
      </div>
      {
        newPeopleList && (
          <div className="person__team">
            <section className="person__team-title">
              <hr />
              <h2>{peopleList.title}</h2>
              <p>{peopleList.subtitle}</p>
            </section>
            <Carousel breakpoints={breakpoints}>
              {
                newPeopleList.map( (onePerson) => (
                  <div key={onePerson?.contentful_id}>
                    <CardPerson person={onePerson}/>
                  </div>
                ))
              }
            </Carousel>
          </div>
        )
      }
    </div>
  );
};

export default Person;

export const personQuery = graphql`
  query QUERY_PERSON($contentful_id: String!, $peopleListId: String!) {
    navigation: contentfulNavigationNavMenu(
      contentful_id: { eq: "28QWR4Rx641pUxsQaVNB1m" }
    ) {
      ...NavMenu
    }

    seo: contentfulPerson(contentful_id: { eq: $contentful_id }) {
      ...PersonSeo
    }

    person: contentfulPerson(contentful_id: { eq: $contentful_id }) {
      ...PersonFull
    }

    peopleList: contentfulPeopleList(contentful_id: { eq: $peopleListId }) {
      ...PeopleListFull
    }
  }
`;
