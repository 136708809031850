import React, { useState, useRef, useEffect } from 'react';

import Button from '@elements/Button';
import CaretLeft from '@svg/icons/caret-left.svg';
import CaretRight from '@svg/icons/caret-right.svg';
import { resizeItems, moveSlides, getClasses } from '@utils';

import './style.css';

type CarouselProps = {
  children?: React.ReactNode[],
  breakpoints?: {
    sm?: number,
    md?: number,
    lg?: number,
    xl?: number,
    xxl?: number,
  },
  buttonsTop: boolean,
}

const Carousel = ({
  children,
  breakpoints,
  buttonsTop = false,
}: CarouselProps): JSX.Element => {
  const carouselInnerRef = useRef(null);
  const carouselContainerRef = useRef(null);
  const [btnPressed, setBtnPressed] = useState(false);

  useEffect(() => {
    resizeItems(carouselContainerRef, breakpoints);
  }, [carouselContainerRef]);

  useEffect(() => {
    const handleResize = () => resizeItems(carouselContainerRef, breakpoints);
    window.addEventListener('resize', handleResize);
  }, []);

  const handleSlide = (right: boolean, left: boolean): void => {
    moveSlides(
      carouselInnerRef,
      right,
      left,
      500,
      btnPressed,
      setBtnPressed,
    );
  };

  const mainClasses = getClasses(
    "carousel--main",
    buttonsTop && "carousel--btns-top",
  );

  return (
    <div className={mainClasses}>
      <div
        ref={carouselContainerRef}
        className="carousel__container"
      >
        <section
          ref={carouselInnerRef}
          className="carousel__inner"
        >
          <div>{children}</div>
        </section>
        <section className="carousel__btn-left">
          <Button
            ariaLabel="icon-left"
            childClassName="btn--icon-only"
            onClick={() => handleSlide(null, true)}
          >
            <CaretLeft />
          </Button>
        </section>
        <section className="carousel__btn-right">
          <Button
            ariaLabel="icon-left"
            childClassName="btn--icon-only"
            onClick={() => handleSlide(true, null)}
          >
            <CaretRight />
          </Button>
        </section>
      </div>
    </div>
  );
};

export default Carousel;
